import { Pageable } from '@shared/services/utils';

export class chatConfigDTO {
  chatType: string;
  companyPropertyId: number;
  ticketId: string;
  roomChatType: string;
  members: any;
  roomChatMessageType: any;
  roomId: any;
  roomName: string;
  roomProfileUrl: string;
  id: any;
  chatMessageType: any;
  userToken: any;
  timeStamp: any;
  messageType: string;
  page: number;
  size: number;
  target: number;
  message: string;
  chatAttachments: Attachments[] = [];
  contentType: string;
  clientMessageId: string;
  chatId: number;
  sender: ChatUser = new ChatUser();
  recipients: any;
  timestamp: any;
  isNotificationActive: boolean;
  imageUrl: string;
  deviceUniqueId: string;
  parentMessageId: number;
  currentUserReaction: string;
  lotoFormId: string;
  equipmentId: string;
}

export interface ReactionsList {
  userToken: string;
  userName: string;
  displayName: string;
  firstName: string;
  lastName: string;
  profilePicUrl: string;
  emailAddress: string;
  messageId: number;
  reactionType: string;
  timeStamp: string;
}

export class ChatUser {
  id: number;
  userToken: any;
  displayName: string;
  userName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  senderId: any;
  profilePicUrl: string;
  lastSeen: any;
  isOnline: any;
  reactions: any;
}

export interface Reactions {
  reaction: string;
}

export class ConversationDTO {
  chatType: string;
  currentUserType: string;
  entireLastMessage: chatConfigDTO = new chatConfigDTO();
  isArchived: boolean;
  isUserLeft: boolean;
  lastMessageSentTime: any;
  propertyName: string;
  roomChatType: string;
  roomCreatedTime: any;
  roomCreatorId: string;
  roomId: number;
  roomMembersCount: number;
  roomName: string;
  unreadMessageCount: number;
  mute: boolean;
}

export class SearchedGroups extends Pageable {
  content: ConversationDTO[] = [];
}

export class SearchedMessages extends Pageable {
  content: chatConfigDTO[] = [];
}
export class Attachments {
  url: string;
  type: string;
}
