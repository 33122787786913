import { HttpParams } from '@angular/common/http';
import { SortDirection } from '@angular/material/sort';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach(key => {
      options = options.set(key, req[key]);
    });
  }
  return options;
};

export class PageableQuery {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  timeZone: string;
}
export class UserPageableQuery {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  search: string;
  status: boolean;
  propertyId: string;
}

export class InspectionPagebleQuery extends UserPageableQuery {
  propertyId: string;
  fromDate: string;
  toDate: string;
  inspectedBy: string;
  inspectionFor: string;
  companyPropertyId: number;
  timeZone: string;
}

export class PageableQueryInspection {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  propertyId: string;
  companyPropertyId: number;
  timeZone: string;
}

export class QRCodePageableQuery {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  key: string;
  propertyId: string;
  companyPropertyId: number;
  timeZone: string;
}

export class QrNotesPageableQuery extends PageableQuery {
  qrCodeNotesSearchDTO: QrNoteParams = new QrNoteParams();
}
export class QrNoteParams {
  endDate: string;
  qrCodeId: string;
  status: boolean;
  startDate: string;
}

export class PageableQueryTenant {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  propertyId: string;
}

export class NoteParam extends PageableQuery {
  ticketNumber: string;
}

export class AttachmentParam extends PageableQuery {
  noteId: number;
}

export class Pageable {
  pageable: PageableObject;
  totalElements: number;
  totalPages: number;
  last: boolean;
  numberOfElements: number;
  first: boolean;
  sort: Sort;
  size: number;
  number: number;
}

export class PageableObject {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: Sort;
  unpaged: boolean;
}

export class PageableQueryLoopVendorPOC {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  loopVendorId: string;
  propertyId: string;
}
export class PageableQueryLoopPropertyPOC {
  size: number;
  page = 0;
  fields: string;
  sort: string | SortDirection;
  loopPropertyId: string;
}
export class PageableQueryChatProperty {
  size: number;
  page = 0;
  fields: string;
  search: string;
  sort: string | SortDirection;
  timeZone: string;
}

export class Sort {
  sorted: boolean;
  unsorted: boolean;
}

// Any number of string can be concatenated from this method
export const concatenateString = (...str: string[]): string => {
  return str.join(', ');
};

export class NotificationPageableQuery {
  size = 15;
  page = 0;
}

// To show See More/ See Less on basis of content
export const getSeeMoreOnBasisOfContent = (i: number) => {
  const description = 'd' + i;
  const link = 'a' + i;
  const x = document.getElementById(description);
  const y = document.getElementById(link);
  if (x && x.clientHeight > 30 && y) {
    y.style.visibility = 'visible';
  } else if (y) {
    y.style.visibility = 'hidden';
  }
};

export class PageAbleQueryLotoChat {
  size: number = 25;
  page = 0;
  keyword: string = '';
  companyPropertyId: number;
}
