import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { messages } from '@shared/constants/app.constants';
import { Tickets } from '@shared/models';
import { chatConfigDTO } from '@shared/models/chat.model';
import { User } from '@shared/models/user.model';
import { PageableQuery } from '@shared/services/utils';
import { Subscription } from 'rxjs';
import { ChatService } from '../chat.service';
import { ChatConfigService } from '../chat_config.service';
@Component({
  selector: 'sfl-chat-user-list',
  templateUrl: './chat-user-list.component.html',
  styleUrls: ['./chat-user-list.component.scss'],
})
export class ChatUserListComponent implements OnInit, OnDestroy {
  @Output() backToPropertyList: EventEmitter<string> = new EventEmitter<string>();
  @Output() startConversationRoom: EventEmitter<string> = new EventEmitter<string>();
  @Output() startConversationInfo: EventEmitter<string> = new EventEmitter<string>();
  @Input('propertyId') companyProperty: any;
  @Input('selectedTicket') selectedTicket: any;
  @Input('groupInfo') groupInfo: any;
  @Input('lotoChatDetails') lotoChatDetails: any;
  searchText: string;
  subscriptions = new Subscription();
  pageableQuery: PageableQuery = new PageableQuery();
  dataSource = new MatTableDataSource<User>();
  totalElements: number;
  isSpinner = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedUsers = [];
  IMAGE_PATH = messages.DEFAULT_IMAGE_PATH;
  createChatRoom: chatConfigDTO = new chatConfigDTO();
  ticketDetails: Tickets = new Tickets();
  constructor(private readonly chatService: ChatService, private readonly chatConfigService: ChatConfigService) {}

  ngOnInit() {
    if (this.selectedTicket && !this.groupInfo) {
      this.getUserListByTicketNumber();
    } else if (this.companyProperty && !this.groupInfo && !this.lotoChatDetails) {
      this.getBuildingList(this.companyProperty.companyPropertyId);
    } else if (this.lotoChatDetails && !this.groupInfo) {
      this.getLotoChatUserList();
    } else if (this.groupInfo) {
      if (this.groupInfo.roomChatType === 'PROPERTY_BASE') {
        this.getPropertyUserByRoomId();
      } else if (this.groupInfo.roomChatType === 'TICKET_BASE') {
        this.getTicketUserByRoomId();
      } else {
        this.getUserByRoomIdForLotoChat();
      }
    }
  }

  getBuildingList(companyPropertyId) {
    this.isSpinner = true;
    this.subscriptions.add(
      this.chatService.getUserByPropertyId(companyPropertyId).subscribe((res: any) => {
        if (res) {
          this.setListData(res);
        }
      }),
    );
  }

  getLotoChatUserList() {
    this.isSpinner = true;
    const params = {
      companyPropertyId: this.lotoChatDetails.companyPropertyId,
      lotoFormId: this.lotoChatDetails.id,
    };
    this.subscriptions.add(
      this.chatService.getUserByForLotoChat(params).subscribe((res: any) => {
        if (res) {
          this.setListData(res);
        }
      }),
    );
  }
  getUserListByTicketNumber() {
    this.isSpinner = true;
    this.subscriptions.add(
      this.chatService.getUserByTicketId(this.selectedTicket.id).subscribe((res: any) => {
        if (res) {
          this.setListData(res);
        }
      }),
    );
  }
  getPropertyUserByRoomId() {
    this.isSpinner = true;
    this.subscriptions.add(
      this.chatService.getPropertyUserByRoomId(this.groupInfo.roomId).subscribe((res: any) => {
        if (res) {
          this.setListData(res);
        }
      }),
    );
  }
  getTicketUserByRoomId() {
    this.isSpinner = true;
    this.subscriptions.add(
      this.chatService.getTicketUserByRoomId(this.groupInfo.roomId).subscribe((res: any) => {
        if (res) {
          this.setListData(res);
        }
      }),
    );
  }

  getUserByRoomIdForLotoChat() {
    this.isSpinner = true;
    this.subscriptions.add(
      this.chatService.getLotoChatUserByRoomId(this.groupInfo.roomId).subscribe((res: any) => {
        if (res) {
          this.setListData(res);
        }
      }),
    );
  }
  setListData(res) {
    res.map(obj => {
      obj.checked = false;
      obj.fullName = obj.firstName + ' ' + obj.lastName;
      return obj;
    });
    this.isSpinner = false;
    this.dataSource = new MatTableDataSource<User>(res);
  }
  onClickPropertyList() {
    if (this.selectedTicket) {
      this.backToPropertyList.emit('Ticket');
    } else if (this.lotoChatDetails) {
      this.backToPropertyList.emit('loto');
    } else {
      this.backToPropertyList.emit('Property');
    }
    this.removeAllData();
  }
  onClickConversationRoom() {
    console.log('onClickConversationRoom');
    if (this.lotoChatDetails) {
      this.createChatRoom.companyPropertyId = this.lotoChatDetails.companyPropertyId;
      this.createChatRoom.roomName = this.lotoChatDetails.equipmentId;
      this.createChatRoom.roomChatType = 'LOTO_BASE';
      this.createChatRoom.lotoFormId = this.lotoChatDetails.id;
      this.createChatRoom.equipmentId = this.lotoChatDetails.equipmentId;
    } else if (this.selectedTicket) {
      this.createChatRoom.companyPropertyId = this.selectedTicket.currentUserCompanyPropertyId;
      this.createChatRoom.roomName = this.selectedTicket.ticketNumber;
      this.createChatRoom.roomChatType = 'TICKET_BASE';
    } else {
      this.createChatRoom.companyPropertyId = this.companyProperty.companyPropertyId;
      this.createChatRoom.roomName = this.companyProperty.propertyName;
      this.createChatRoom.roomChatType = 'PROPERTY_BASE';
    }
    this.startConversationRoom.emit();
    this.createChatRoom.ticketId = this.selectedTicket ? this.selectedTicket.id : '';
    let selectedUsersNames = this.selectedUsers.map(u => u.fullName).join('/ ');
    let joinRoomName = this.createChatRoom.roomName + '/ ' + selectedUsersNames;
    this.createChatRoom.roomName = joinRoomName;
    let selectedId = this.selectedUsers.map(u => u.id).join(',');
    this.createChatRoom.members = selectedId.split(',');
    this.createChatRoom.chatType = 'ROOM';
    this.createChatRoom.userToken = sessionStorage.getItem('userId');
    this.createChatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.createChatRoom(this.createChatRoom);
    this.removeAllData();
  }
  onClickJoinRoom() {
    console.log('onClickJoinRoom');
    this.startConversationInfo.emit(this.groupInfo);
    let selectedId = this.selectedUsers.map(u => u.id).join(',');
    this.createChatRoom.members = selectedId.split(',');
    this.createChatRoom.roomId = this.groupInfo.roomId;
    this.createChatRoom.userToken = sessionStorage.getItem('userId');
    this.createChatRoom.deviceUniqueId = sessionStorage.getItem('deviceUniqueId');
    this.chatConfigService.addNewUserToChatRoom(this.createChatRoom);
    this.removeAllData();
  }
  onClickSelect(event, item, index) {
    let indexOf = this.dataSource.filteredData.findIndex(x => x.id === item.id);
    if (this.dataSource.filteredData[indexOf].checked) {
      this.dataSource.filteredData[indexOf].checked = false;
      let indexOfSelectedUsers = this.selectedUsers.findIndex(x => x.id === item.id);
      this.selectedUsers.splice(indexOfSelectedUsers, 1);
    } else {
      this.dataSource.filteredData[indexOf].checked = true;
      this.selectedUsers.push(item);
    }
  }
  removeAllData() {
    this.selectedTicket = null;
    this.companyProperty = null;
    this.lotoChatDetails = null;
    this.createChatRoom = new chatConfigDTO();
    this.selectedUsers = [];
    this.groupInfo = null;
    this.ngOnDestroy();
  }
  ngOnDestroy() {
    console.log('Items destroyed1');
    if (this.subscriptions) {
      console.log('Items destroyed2');
      this.subscriptions.unsubscribe();
    }
  }
}
