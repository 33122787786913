import { environment } from '@environment/environment';

const SERVER_API_URL = environment.serverUrl;
const SERVER_WEB_URL = environment.webUrl;
export const URL = {
  LOGIN_URL: SERVER_API_URL + '/authenticate',
  LOGOUT_URL: SERVER_API_URL + '/logout',
  FORGET_PASSWORD_INIT: SERVER_API_URL + '/forgot-password-init',
  RESET_PASSWORD_URL: SERVER_API_URL + '/reset-password',
  ACCOUNT_INFO: SERVER_API_URL + '/account-info',
  UPDATE_PROFILE_PICTURE: SERVER_API_URL + '/upload-image',
  GET_USER_PROFILE: SERVER_API_URL + '/profiles',
  FETCH_PROFILE_IMAGE: SERVER_API_URL + '/fetch-profile-image',
  ADD_CLEANING_COMPANY: SERVER_API_URL + '/cleaning-companies',
  SELECT_NONASSOCIATE_ADMIN: SERVER_API_URL + '/users/not-associate',
  GET_STATES: SERVER_API_URL + '/states',
  ADD_USER: SERVER_API_URL + '/users',
  ADD_PROPERTY_COMPANY: SERVER_API_URL + '/pmc-companies',
  UPDATE_NOT_ASSOCIATE: SERVER_API_URL + '/users/admin-candidate/',
  ADD_COMPANY_USER: SERVER_API_URL + '/companies/users',
  ADD_PROPERTY: SERVER_API_URL + '/properties',
  FETCH_CC_POC: SERVER_API_URL + '/companies/users',
  FETCH_CC_EXTERNAL_POC: SERVER_API_URL + '/companies/users/external',
  FETCH_PMC: SERVER_API_URL + '/all-pmc-companies',
  FETCH_PROPERTIES: SERVER_API_URL + '/properties',
  FETCH_PROPERTIES_BY_COMPANY: SERVER_API_URL + '/companies/properties',
  FETCH_USER_BY_COMPANY: SERVER_API_URL + '/companies/users/all',
  UPDATE_PROPERTY: SERVER_API_URL + '/properties/basic-details',
  REPLACE_POC: SERVER_API_URL + '/properties/change-poc',
  UPDATE_CC_POC_LIST: SERVER_API_URL + '/properties/poc-users',
  GET_USER_DATA_BY_EMAIL: SERVER_API_URL + '/users/',
  GET_COMPANY_COUNT: SERVER_API_URL + '/companies/counts',
  GET_COMPANY_COUNTT_ON_PROPERTY_SELECT: SERVER_API_URL + '/companies/dashboard/counts',
  GET_TICKET_COUNT: SERVER_API_URL + '/tickets/counts',
  GET_TICKET_COUNT_ON_PROPERTY_SELECT: SERVER_API_URL + '/tickets/dashboard/counts',
  GET_PROPERTY_COUNT: SERVER_API_URL + '/properties/counts',
  GET_INSPECTION_COUNT: SERVER_API_URL + '/inspections/counts',
  GET_INSPECTION_COUNT_ON_PROPERTY_SELECT: SERVER_API_URL + '/inspections/dashboard/counts',
  GET_EVENT_COUNT: SERVER_API_URL + '/events/counts',
  GET_EVENT_COUNT_ON_PROPERTY_SELECT: SERVER_API_URL + '/events/dashboard/counts',
  GET_SURVEY_COUNT_ON_PROPERTY_SELECT: SERVER_API_URL + '/survey-templates/count',
  GET_UNASSIGNED_TICKET_COUNT: SERVER_API_URL + '/unassigned-tickets/counts',
  GET_UNASSIGNED_TICKET_COUNT_ON_PROPERTY_SELECT: SERVER_API_URL + '/unassigned-tickets/dashboard/counts',
  GET_OBSERVER_LIST: SERVER_API_URL + '/tickets/observers',
  GET_PROPERTY_LIST: SERVER_API_URL + '/properties/property-names',
  GET_ASSIGNEE_LIST: SERVER_API_URL + '/tickets/assignees',
  GET_QR_CODE_ASSIGNEE_LIST: SERVER_API_URL + '/company-property-users/qr-code-assignees',
  UPLOAD_ATTACHMENT: SERVER_API_URL + '/tickets/upload-attachments',
  GET_TAG_LIST: SERVER_API_URL + '/tags',
  ADD_TICKET: SERVER_API_URL + '/tickets',
  GET_COMPANY_DETAIL: SERVER_API_URL + '/companies',
  ADD_SURVEY: SERVER_API_URL + '/survey-questions',
  GET_ALL_OBSERVERS_AND_ASSIGNEES: SERVER_API_URL + '/observers-and-assignees',
  GET_USER_BY_TICKET_ID: SERVER_API_URL + '/tickets/info',
  API_USERS_URL: 'api/users',
  API_TICKET_URL: 'api/ticket',
  ADD_DEFAULT_OBSERVER: SERVER_API_URL + '/observers',
  ADD_NOTE: SERVER_API_URL + '/tickets/notes',
  GET_REALTIME_NOTE: '/tickets/info/notes/reply',
  ADD_INSPECTION_QUESTION_CONFIG: SERVER_API_URL + '/inspection-options',
  GET_INSPECTION_QUESTION_CONFIG: SERVER_API_URL + '/inspection-options',
  API_INSPECTION_URL: 'api/inspectionQuestionConfig',
  ADD_INSPECTION_QUESTION: SERVER_API_URL + '/inspection-questions',
  UPLOAD_DOCUMENT: SERVER_API_URL + '/inspection-questions/import',
  ADD_EVENT: SERVER_API_URL + '/events',
  GET_ALL_EVENTS: SERVER_API_URL + '/all-events',
  CHANGE_TICKET_STATUS: SERVER_API_URL + '/tickets/status/',
  ADD_QRCODE: SERVER_API_URL + '/qr-codes',
  API_QRCODE_URL: 'api/qrCode',
  MANAGE_AREA: SERVER_API_URL + '/areas',
  GET_QRCODE: SERVER_API_URL + '/qr-codes',
  UPDATE_QRCODE: SERVER_API_URL + '/qr-codes',
  GENERATEDQRCODE: SERVER_API_URL + '/qr-codes/generate',
  DELETE_QRCODE: SERVER_API_URL + '/qr-codes/',
  GET_NOTE_LIST: SERVER_API_URL + '/tickets/info/notes/reply',
  GET_ATTACHMENT_LIST: SERVER_API_URL + '/tickets/info/note-attachments',
  GET_EVENT_ID: SERVER_API_URL + '/events/by-property',
  GET_PROPERTY_TYPE: SERVER_API_URL + '/survey-questions/property-type',
  SEND_SURVEY: SERVER_API_URL + '/survey-questions/assignees',
  COPY_SURVEY: SERVER_API_URL + '/survey-questions/copy',
  UPDATE_SURVEY: SERVER_API_URL + '/survey-questions',
  DELETE_QUESTION: SERVER_API_URL + '/survey-questions/',
  ADD_SURVEY_OPTION: SERVER_API_URL + '/survey-options',
  GET_PMC_POC_BY_PROPERTY: SERVER_API_URL + '/management-company',
  GET_INSPECTIONS: SERVER_API_URL + '/inspections',
  API_INSPECTIONS_URL: 'api/inspections',
  GET_TENANT_ID: SERVER_API_URL + '/properties/info/tenant',
  GET_FEEDBACKLIST: SERVER_API_URL + '/feedback',
  GET_USER_BY_INSPECTIONID: SERVER_API_URL + '/inspections',
  GET_ATTACHMENT_LIST_INSPECTIONS: SERVER_API_URL + '/inspections/notes',
  GET_REPORTS: SERVER_API_URL + '/reports',
  GET_SURVEY_DETAIL: SERVER_API_URL + '/surveys/detail/',
  GET_INSPECTION_COLOR_LIST: SERVER_API_URL + '/colors',
  GET_PMC_POC_BY_COMPANY: SERVER_API_URL + '/company',
  SUBSCRIBE_TO_TOPIC: SERVER_API_URL + '/subscribe',
  UNSUBSCRIBE_TO_TOPIC: SERVER_API_URL + '/unsubscribe',
  GET_NOTIFICATION_LISTING: SERVER_API_URL + '/notification-histories',
  READ_SINGLE_NOTIFICATION: SERVER_API_URL + '/mark-read',
  GET_UNREAD_NOTIFICATION_COUNT: SERVER_API_URL + '/notification-histories/unread/count',
  MARK_ALL_AS_READ: SERVER_API_URL + '/mark-all',
  DELETE_INSPECTION_OPTION: SERVER_API_URL + '/inspection-options',
  PROPERTY_USER: SERVER_API_URL + '/property-users',
  LOOP_VENDORS: SERVER_API_URL + '/loop-vendors',
  LOOP_VENDORS_POC: SERVER_API_URL + '/loop-vendor-pocs',
  LOOP_PROPERTY: SERVER_API_URL + '/loop-properties',
  LOOP_PROPERTY_POC: SERVER_API_URL + '/loop-property-users',
  OBSERVERS: SERVER_API_URL + '/observers',
  GET_ASSIGNEE_LIST_EVENT: SERVER_API_URL + '/events/assignees',
  SEND_QRCODE: SERVER_API_URL + '/qr-codes/send-image',
  GET_OPEN_SURVEY_TEMPLATE: SERVER_API_URL + '/survey-templates',
  ADD_OPEN_SURVEY: SERVER_API_URL + '/survey-templates',
  ADD_OPEN_SURVEY_QUESTION: SERVER_API_URL + '/survey-template-questions',
  UPDATE_OPEN_SURVEY_PICTURE: SERVER_API_URL + '/upload-company-logo',
  GET_OPEN_SURVEY_BY_ID: SERVER_API_URL + '/survey-performer-form',
  GET_PROPERTY_USERS_BY_PROPERTY_ID: SERVER_API_URL + '/property-users/survey-template-assignee',
  CHANGE_OPEN_SURVEY_TEMPLATE_STATE: SERVER_API_URL + '/survey-templates/',
  ADD_OPEN_SURVEY_OUTSIDE_USER: SERVER_API_URL + '/survey-submit',
  ADD_OPEN_SURVEY_ANSWER: SERVER_API_URL + '/survey-template-answers',
  QRCODE_URL: '/qr-code-url',
  OPEN_SURVEY_CLONE: '/clone',
  GET_OPEN_SURVEY_CONSOLIDATED_RESPONSE: SERVER_API_URL + '/survey-template-responses/for-chart',
  GET_OPEN_SURVEY_PERFORMERS: SERVER_API_URL + '/survey-template-responses/all',
  GET_OPEN_SURVEY_RESPONSE_DETAILS: SERVER_API_URL + '/survey-template-individual-responses',
  GET_SURVEY_COMPARISON: SERVER_API_URL + '/survey-template-comparison',
  GET_PERFORMER_INFO_BY_TICKET_ID: SERVER_API_URL + '/survey-performer-info-by-ticket',
  GET_INSPECTED_BY_USER: SERVER_API_URL + '/inspection-by-users',
  GET_INSPECTION_FOR_USER: SERVER_API_URL + '/inspection-for-users',
  ADD_SURVEY_PERFORMER_DESCRIPTION: SERVER_API_URL + '/survey-performer-info-contacted-by',
  DUPLICATE_INSPECTION_QUESTION: SERVER_API_URL + '/copy-inspection-questions',
  PROPERTY_USER_FEATURE: SERVER_API_URL + '/property-user-feature',
  PROFILE_BY_EMAIL: SERVER_API_URL + '/users-info',
  QR_CODE_INFO: SERVER_API_URL + '/qr-codes-info',
  QR_CODE_NOTES: SERVER_API_URL + '/qr-code-notes',
  QR_CODE_INFO_BY_ID: SERVER_API_URL + '/qr-code-urls',
  CHECKINS: SERVER_API_URL + '/checkins',
  COMPANY: SERVER_API_URL + '/company',
  COMPANY_PROPERTIES: SERVER_API_URL + '/company-properties',
  COMPANY_PROPERTY: SERVER_API_URL + '/company-property',
  COMPANIES: SERVER_API_URL + '/companies',
  COMPANY_PROPERTY_USERS: SERVER_API_URL + '/company-property-users',
  NOTE_REACTION: SERVER_API_URL + '/note-user-reactions',
  GET_REALTIME_NOTE_REACTION: '/tickets',
  GET_PROPERTY_USERS: SERVER_API_URL + '/property/users',
  GET_PROPERTY_CHAT: SERVER_API_URL + '/properties/property-names',
  GET_TICKETS_BY_PROPERTY_CHAT: SERVER_API_URL + '/tickets/search',
  GET_TICKET_USER_CHAT: SERVER_API_URL + '/ticket/users',
  GET_SENTIMENTS: SERVER_API_URL + '/sentiments',
  GET_CATEGORIES: SERVER_API_URL + '/categories',
  COMMENTS: SERVER_API_URL + '/comments',
  COMMENT: SERVER_API_URL + '/comment',
  REACTED_USERS: SERVER_API_URL + '/reacted-users',
  SEARCH_GROUPS: SERVER_API_URL + '/search/groups',
  SEARCH_MESSAGES: SERVER_API_URL + '/search/messages',
  MUTE_UNMUTE_NOTIFICATION: SERVER_API_URL + '/room-member/mute',
  UNREAD_CHAT_COUNT: SERVER_API_URL + '/chat/unread-chat-count',

  SOCKET_URL: SERVER_WEB_URL,
  GET_ROOM_MEMBERS_DETAILS: SERVER_API_URL + '/room-members/details',
  UPLOAD_ATTACHMENTS: SERVER_API_URL + '/chat/files/sync',
  //For RXA
  GET_UUID_FOR_RXA: SERVER_API_URL + '/initiate-redirection',
  GET_TOKEN_FOR_RX: SERVER_API_URL + '/finish-redirection',
  // For Accept Terms
  ACCEPT_TERMS_AND_CONDITION: SERVER_API_URL + '/terms-and-condition/accept',
  GENERATE_REMOVE_USER_PROFILE_QR_CODE: SERVER_API_URL + '/profile/qr-code',
  GET_LOTO_FORM_LIST: SERVER_API_URL + '/loto/forms',
  GET_LOTO_CHAT_USERS: SERVER_API_URL + '/loto/users',
};
