import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDividerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatTabsModule,
  MatTooltipModule,
} from '@angular/material';

import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ChatListHistoryComponent } from './chat-list-history/chat-list-history.component';
import { ChatLotoFormListComponent } from './chat-loto-form-list/chat-loto-form-list.component';
import { ChatPropertyListComponent } from './chat-property-list/chat-property-list.component';
import { ChatSearchGroupMessageComponent } from './chat-search-group-message/chat-search-group-message.component';
import { ChatTicketListComponent } from './chat-ticket-list/chat-ticket-list.component';
import { ChatUserListComponent } from './chat-user-list/chat-user-list.component';
import { ConversationInfoComponent } from './conversation-info/conversation-info.component';
import { ConversationRoomComponent } from './conversation-room/conversation-room.component';

@NgModule({
  declarations: [
    ChatListHistoryComponent,
    ChatPropertyListComponent,
    ChatUserListComponent,
    ConversationRoomComponent,
    ConversationInfoComponent,
    ChatTicketListComponent,
    ChatSearchGroupMessageComponent,
    ChatLotoFormListComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    MatDividerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTabsModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FormsModule,
    Ng2SearchPipeModule,
    InfiniteScrollModule,
    PerfectScrollbarModule,
    MatMenuModule,
    MatCardModule,
    MatChipsModule,
    NgbModule,
    SharedModule,
  ],
  exports: [
    ChatListHistoryComponent,
    ChatPropertyListComponent,
    ChatUserListComponent,
    ConversationRoomComponent,
    ConversationInfoComponent,
    ChatTicketListComponent,
    ChatSearchGroupMessageComponent,
    ChatLotoFormListComponent,
  ],
})
export class ChatModule {}
