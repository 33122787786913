import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { LayoutUtilsService, MessageType } from '@layout/services';
import { TicketFilter } from '@shared/models/tickets.model';
import { PageAbleQueryLotoChat } from '@shared/services/utils';
import { Subscription } from 'rxjs';
import { ChatService } from '../chat.service';

@Component({
  selector: 'sfl-chat-loto-form-list',
  templateUrl: './chat-loto-form-list.component.html',
  styleUrls: ['./chat-loto-form-list.component.scss'],
})
export class ChatLotoFormListComponent implements OnInit {
  @Output() backToPropertyList: EventEmitter<string> = new EventEmitter<string>();
  @Output() startUserListFromLoto: EventEmitter<any> = new EventEmitter<any>();
  @Input('propertyId') companyProperty: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  conversationLotoList: any = [];
  isSpinner = false;
  subscriptions = new Subscription();
  dataSource = [];
  pageAbleQuery: PageAbleQueryLotoChat = new PageAbleQueryLotoChat();
  ticketFilter: TicketFilter = new TicketFilter();
  defaultFilter = new TicketFilter();
  searchText: string;
  selectedIndex = 0;
  selectedLotoForm: any;
  totalElements = 0;
  constructor(private readonly chatService: ChatService, private readonly layoutUtilsService: LayoutUtilsService) {}

  ngOnInit() {
    if (this.companyProperty) {
      this.pageAbleQuery.companyPropertyId = this.companyProperty.companyPropertyId;
      this.getLotoFormListing();
    }
  }
  getLotoFormListing() {
    this.isSpinner = true;
    this.subscriptions.add(
      this.chatService.getLotoFormList(this.pageAbleQuery).subscribe(
        (res: any) => {
          if (res) {
            this.conversationLotoList.push(...res.content);
            this.totalElements = res.totalElements;
            this.selectedLotoForm = this.conversationLotoList[0];
            this.pageAbleQuery.page = res.pageable.pageNumber + 1;
            this.isSpinner = false;
          }
        },
        (error: HttpErrorResponse) => {
          this.isSpinner = false;
          this.layoutUtilsService.showActionNotification(error.error.message, MessageType.Delete);
        },
      ),
    );
  }

  onScroll() {
    if (!this.isSpinner && this.totalElements > this.conversationLotoList.length) {
      this.getLotoFormListing();
    }
  }

  onSearchTicket(event) {
    event = event.trim();
    if (event.length <= 3) {
      return;
    }
    this.conversationLotoList = [];
    this.pageAbleQuery.page = 0;
    this.pageAbleQuery.keyword = event;
    this.getLotoFormListing();
  }

  onClickPropertyList() {
    this.backToPropertyList.emit('loto');
  }

  onClickUserList() {
    const selectedLotoForChat = {
      ...this.selectedLotoForm,
      companyPropertyId: this.companyProperty.companyPropertyId,
    };

    this.startUserListFromLoto.emit(selectedLotoForChat);
  }
  onClickSelect(events, item, index) {
    this.selectedIndex = index;
    this.selectedLotoForm = item;
  }
  getSelected(items) {
    if (items.id === this.selectedLotoForm.id) {
      return true;
    } else {
      return false;
    }
  }
}
